import { generateRouteFromDeepLink } from "@ms/uno-routing/lib/local/utilities/DeepLinkUtils";
// Base pre-fetcher
import { DataPreFetcher } from "../../base/preFetcher/DataPreFetcher";
// Teams SDK
import { app, authentication } from "@microsoft/teams-js-uno";
// Host adapator
import { tryParseTabRoute } from "@ms/uno-hostadaptors/lib/local/utils/TeamsRouteUtils";
import { generateRouteFromLegacyDeepLink } from "@ms/uno-hostadaptors/lib/local/utils/LegacyTeamsDeepLinkUtils";
/**
 * Data pre-fetcher that is used to prefetch data for Teams app
 */
export class TeamsAppDataPreFetcher extends DataPreFetcher {
    async setupHostDependencies() {
        if (!app.isInitialized()) {
            await app.initialize();
            const teamsContext = await app.getContext();
            const userClickTime = teamsContext.app.userClickTime;
            const markExtraData = userClickTime ? { userClickTime: String(userClickTime) } : undefined;
            this.mark("InitializedTeamsSDK", markExtraData);
        }
    }
    async getInitialDeeplinkRoute() {
        if (!app.isInitialized()) {
            return null;
        }
        const teamsContext = await app.getContext();
        const tabRoute = tryParseTabRoute(teamsContext.page.id, teamsContext.page.subPageId, teamsContext.page.frameContext, null);
        if (tabRoute) {
            return tabRoute;
        }
        const newPath = teamsContext.page.subPageId;
        return generateRouteFromDeepLink(newPath, null, generateRouteFromLegacyDeepLink);
    }
    async getAccessToken(resource) {
        // For Prod MS Graph resource, use retailservices graph token OBO endpoint
        if (resource === "https://graph.microsoft.com") {
            return this.preFetcherServices.getGraphTokenFromRetailService();
        }
        else {
            const authTokenRequestParams = {
                resources: [resource],
            };
            const token = await authentication.getAuthToken(authTokenRequestParams);
            return token;
        }
    }
    async getUserId() {
        if (!app.isInitialized()) {
            await this.setupHostDependencies();
        }
        const teamsContext = await app.getContext();
        const user = teamsContext.user;
        if (!user) {
            throw new Error("The current user is not authenticated");
        }
        return user.id;
    }
}
